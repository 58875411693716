import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { PaletteMode } from "@mui/material";
import { grey, green } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import GHHome from "./components/gh-home";
import GHTestimonials from "./components/gh-testimonials";
import GHContact from "./components/gh-contact";
import GHProducts from "./components/gh-products";
import BasicMenu from "./components/basic-menu";
import GHGallery from "./components/gh-gallery";

import FourZeroFour from "./components/four-zero-four";
import FooterComponent from "./components/footer-component";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function MyApp() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}
        sx={{width:'auto', height:'auto'}}
      >
        <Grid item>
          <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <BasicMenu />
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              lg={10}
              sx={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }}
            >
              {theme.palette.mode.charAt(0).toUpperCase() + theme.palette.mode.substring(1)} Mode
              <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}>
            <Grid
              item
              xs={12}
              sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
            >
              <NavLink to="/home">
                <img src="logo_m.png" alt="Georgas Heritage"></img>
              </NavLink>
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}>
            <Grid
              item
              xs={12}
              sx={{ m:4, alignItems: "center", display: "flex", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  color: green[800], alignItems: "center", display: "flex", justifyContent: "center", fontSize: 30
                }}
                align='center'
              >
                Finest Quality Extra Virgin Greek Olive Oil
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}
            sx={{ fontFamily: "Amiko", fontSize: 18 }}
          >
            <Grid item xs={12}>
              <Routes>
                <Route path="/" element={<GHHome />} />
                <Route path="/home" element={<GHHome />} />
                <Route path="/testimonials" element={<GHTestimonials />} />
                <Route path="/gallery" element={<GHGallery />} />
                <Route path="/contact" element={<GHContact />} />
                <Route path="/products" element={<GHProducts />} />
                <Route path="*" element={<FourZeroFour />} />
              </Routes>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}
            sx={{ fontFamily: "Amiko", fontSize: 18 }}
          >
            <Grid item xs={12}>
              <FooterComponent></FooterComponent>
            </Grid>
          </Grid>

          <br></br>
          <br></br>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState<"light" | "dark">("light");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            type: "light",
            background: {
              default: "#d7dcd7",
              paper: "#919a91",
            },
            shape: {
              boxBorderRadius: 0,
            },
            text: {
              primary: grey[900],
              secondary: grey[800],
            },
            action: {
              hover: "#d7dcd7",
              hoverOpacity: 1,
            },
            // info: {
            //   main: grey[800],
            //   light: grey[800],
            //   dark: grey[800],
            //   contrastText: grey[800],
            // },
            primary: {
              main: grey[800],
            },
          }
        : {
            type: "dark",
            shape: {
              boxBorderRadius: 0,
            },
            text: {
              primary: "#919a91",
              secondary: "#c8e1c8",
            },
            action: {
              hover: grey[900],
              hoverOpacity: 1,
            },
            primary: {
              main: "#d7dcd7",
            },
          }),
    },
  });

  const theme = createTheme(getDesignTokens(mode));

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <MyApp />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
