import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GalleryComponent from "./gallery-component";
import ShoplinkComponent from "./shoplink-component";
import Link from "@mui/material/Link";

const itemData1 = [
  {
    key: 1,
    img: "/images/22_m.jpg",
    title: "",
  },
  {
    key: 2,
    img: "/images/20_m.jpg",
    title: "",
  },
];

const itemData2 = [
  {
    key: 3,
    img: "/images/23_m.jpg",
    title: "",
  },
];

export default function GHProducts() {
  return (
    <Grid container>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Box sx={{ m: 3 }}>
          <fieldset>
            <legend>
              <h2>&nbsp;Our Products ...when quality matters! &nbsp;</h2>
            </legend>
            <p>
              Georgas Heritage Finest Quality Extra Virgin Olive Oil is a premium product.
              Production is carefully supervised by our team throughout all stages of production,
              from our own olive orchards through the final bottling process.
            </p>
            <GalleryComponent itemData={itemData1}></GalleryComponent>

            <p>
              <b>Georgas Heritage Finest Quality Extra Virgin Olive Oil</b> is a premium product.
              Production is carefully supervised by the Georgas Heritage team through all stages of
              production, from the olive orchards we own to the final bottling.
              <br></br>
              <br></br>
              <li>Premium Extra Virgin olive oil</li>
              <li>100% Natural</li>
              <li>100% Pure</li>
              <li>Cold pressed product to reserve its natural taste and fragrance</li>
              <li>Superior blend of centuries old and new olive trees</li>
              <li>Rich in dietary benefits</li>
              <li>Free of chemicals.</li>
              <li>High Phenolic</li>
              <li>Rich is Antioxidants</li>
              <li>Very low acidity below 0,3</li>
              <li>Green Agriculture</li>
              <li>Ethical Harvesting</li>
              <li>Geographical indication: Monemvasia, Peloponnese – Greece</li>
            </p>

            <ShoplinkComponent></ShoplinkComponent>

            <br></br>
            <br></br>

            <p>
              <b>Georgas Heritage Finest Quality Mayor Olives</b> (Olea europea var.major macrocarpa
              or Gaidourolia). This successful revival of an ancient Greek olive variety produces
              quality table olives that often reach 20 gr each. This rare olive has a rich taste and
              makes a unique appetizer for any dish or drink.
            </p>

            <GalleryComponent itemData={itemData2}></GalleryComponent>

            <p>
              Mayor olives are available for wholesale only. Please&nbsp;
              <Link href="mailto:georgasheritage@gmail.com">contact us</Link>
              &nbsp;for prices and detailed product information.
              <br></br>
            </p>

            <br></br>
            <br></br>

            <ShoplinkComponent></ShoplinkComponent>

            <br></br>
            <br></br>
          </fieldset>
        </Box>
      </Grid>
    </Grid>
  );
}
