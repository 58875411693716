import * as React from "react";
import Box from "@mui/material/Box";

export default function FourZeroFour(props) {
  return (
    <div>
      <Box sx={{ m: 3 }}>
        <br></br>
        <br></br>
        Oops! That page can’t be found.
        <br></br>
        It looks like nothing was found at this location. Maybe try one of the links in the menu or
        press back to go to the previous page.
      </Box>
    </div>
  );
}
