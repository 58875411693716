import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GalleryComponent from "./gallery-component";

const itemData1 = [
  {
    key: 11,
    img: "/images/05_m.jpg",
    title: "Breakfast",
  },
  {
    key: 12,
    img: "/images/06_m.jpg",
    title: "Breakfast",
  },
  {
    key: 13,
    img: "/images/07_m.jpg",
    title: "Breakfast"
  },
  {
    key: 21,
    img: "/images/17_m.jpg",
    title: "Breakfast",
  },
  {
    key: 22,
    img: "/images/24_m.jpg",
    title: "Breakfast",
  },
  {
    key: 23,
    img: "/images/15_m.jpg",
    title: "Breakfast"
  },
  {
    key: 31,
    img: "/images/09_m.jpg",
    title: "Breakfast",
  },
  {
    key: 32,
    img: "/images/25_m.jpg",
    title: "Breakfast",
  },
  {
    key: 33,
    img: "/images/10_m.jpg",
    title: "Breakfast"
  },
  {
    key: 41,
    img: "/images/18_m.jpg",
    title: "Breakfast",
  },
  {
    key: 42,
    img: "/images/23_m.jpg",
    title: "Breakfast",
  },
  {
    key: 43,
    img: "/images/19_m.jpg",
    title: "Breakfast"
  },
  {
    key: 51,
    img: "/images/27_m.jpg",
    title: "Breakfast",
  },
  {
    key: 52,
    img: "/images/28_m.jpg",
    title: "Breakfast",
  },
  {
    key: 53,
    img: "/images/29_m.jpg",
    title: "Breakfast"
  }

];

export default function GHGallery() {
  return (
    <Grid container>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Box sx={{ m: 3}}>
          <fieldset>
            <legend>
              <h2>&nbsp;Gallery&nbsp;</h2>
            </legend>
            <GalleryComponent itemData={itemData1}></GalleryComponent>

            <br></br>

          </fieldset>
        </Box>
      </Grid>
    </Grid>
  );
}
