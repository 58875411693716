import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function FooterComponent() {
  return (
    <Grid container>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Box sx={{ m: 3 }}>
          <fieldset>
            <small>© 2021 Georgas Heritage - All rights reserved.</small>
          </fieldset>
        </Box>
      </Grid>
    </Grid>
  );
}
