import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GalleryComponent from "./gallery-component";
import SeparatorComponent from "./separator-component";

const itemData1 = [
  {
    key: 1,
    img: "/images/09_m.jpg",
    title: "",
  }
];

export default function GHTestimonials() {

  return (
    <Grid container>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Box sx={{ m: 3 }}>
          <fieldset>
            <legend>
              <h2>&nbsp;Testimonials&nbsp;</h2>
            </legend>
            <p>
              This is a finest olive oil indeed. We love the taste, the color and their agriculture
              standards. Will keep ordering!
              <br></br>- <i>Chanel Green, UK</i>
            </p>
            <SeparatorComponent></SeparatorComponent>

            <p>
              The actual pure product! One of the best qualities we've tried in our cuisine! 5 star
              <br></br>- <i>Iolee Caralis, Greece</i>
            </p>
            <SeparatorComponent></SeparatorComponent>

            <p>
              Refined and rich taste! Pure like nature!
              <br></br>- <i>Christina X, Greece</i>
            </p>
            <SeparatorComponent></SeparatorComponent>

            <p>
              I liked the color, the taste and the unique slight bitterness. My girlfriend ordered a
              few bottles for us. Gourmet food for sure!
              <br></br>- <i>Alfred Couch, Iceland</i>
            </p>
            <SeparatorComponent></SeparatorComponent>

            <p>
              We took a bottle with us during our vacation in Greece and now I ordered a few boxes
              for my family for the entire year. You don’t easily find this quality of Mediterranean
              olive oil in Arab countries. Highly recommended
              <br></br>- <i>Faizal Alnaser, businessman, UAE</i>
            </p>
            <SeparatorComponent></SeparatorComponent>

            <p>
              I was captured by their green agriculture standards. For me and my family it is
              important to support only environmental friendly projects. The olive oil itself was
              such a pleasant surprise. So tasteful and with that like yellow green color,
              definitely the best we had ever tried. Will keep ordering and I already recommended
              that to our hiking team. Last but not least the price is great, excellent value for
              money!
              <br></br>- <i>Kathy, Activist, Netherlands</i>
            </p>
            <SeparatorComponent></SeparatorComponent>

            <p>
              Highly recommended! This olive oil is fantastic and in such a good price. The package
              arrived on time. I am in a diet and it helps me control my cholesterol very well. My
              wife put some on her face as a face mask and she is excited too. Will order some more
              for us, and some for our wine baskets for our friends this coming winter. The bottle
              is very elegant and it’s an excellent choice for gift baskets.
              <br></br>- <i>William J., Sweden</i>
            </p>
            <SeparatorComponent></SeparatorComponent>

            <p>
              I am not really a consumer but I am the procurement manager of a Cosmetics firm. Our
              cosmetics are natural and every year we run a big research for low acidity olive oils
              for our cosmetics. The young people representing this company said that their oil has
              up to 0,3 acidity . When we checked with our laboratory it only had 0,1. We buy from
              them for the last 2 years. They have a passion and a deep love for what they do and
              they definitely deliver what they promise.
              <br></br>- <i>Dimitra, Entrepreneur, Greece</i>
            </p>
            <br></br>
            <GalleryComponent itemData={itemData1}></GalleryComponent>

          </fieldset>
        </Box>
      </Grid>
    </Grid>
  );
}
