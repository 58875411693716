import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GalleryComponent from "./gallery-component";

const itemData1 = [
  {
    key: 1,
    img: "/images/30_m.jpg",
    title: "Breakfast",
  }
];

const itemData2 = [
  {
    key: 1,
    img: "/images/05_m.jpg",
    title: "Breakfast",
  },
  {
    key: 2,
    img: "/images/11_m.jpg",
    title: "Breakfast",
  }
];
const itemData3 = [
  {
    key: 3,
    img: "/images/24_m.jpg",
    title: "Breakfast",
  }
];

export default function GHHome() {
  return (
    <Grid container>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Box sx={{ m: 3}}>
          <fieldset>
            <legend>
              <h2>&nbsp;About Us&nbsp;</h2>
            </legend>
            <p>
              <b>Georgas Heritage</b> was established in 1877 and has a long history in the Olive
              oil Market. This four generation family brand has its roots back in the 18th century
              where everything coming from the earth was pure and natural!
            </p>
            <GalleryComponent itemData={itemData1}></GalleryComponent>

            <p>
              This is a <b>Green Agriculture</b> project aligned with 100% natural techniques and
              without the use of chemicals. Natural compost is used instead of chemical fertilizers.
              GH team plants a new tree for every old tree that completes its life cycle. Georgas
              Heritage Finest Oil is produced with high respect to the nature’s balance and its
              works have a positive impact to the environment. Bird nests, bee nests or other animal
              nests on the olive trees are protected.
            </p>
            <GalleryComponent itemData={itemData2}></GalleryComponent>

            <p>
              <b>Ethical harvesting:</b> The Georgas Heritage team has applied high ethics
              throughout all sectors of this project by ensuring that all team is treated in the
              highest standards of professionalism and emotional intelligence.
            </p>
            <GalleryComponent itemData={itemData3}></GalleryComponent>
            <br></br>

          </fieldset>
        </Box>
      </Grid>
    </Grid>
  );
}
