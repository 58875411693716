import React from "react";
import Link from "@mui/material/Link";
import { green } from "@mui/material/colors";
import Typography from "@mui/material/Typography";

export default function ShoplinkComponent() {
  return (
    <Typography
      sx={{
        color: green[800],
        fontSize: 20,
      }}
    >
      For online orders and detailed product offerings please message us on our&nbsp;
      <Link href="https://www.facebook.com/GeorgasHeritage/">
        Facebook Page
      </Link>
    </Typography>
  );
}
