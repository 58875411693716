import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function GalleryComponent(props) {
  return (
    <Grid container spacing={{ xs: 2, md: 8, lg: 16, xl: 32 }} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>

      {props.itemData.map((item) => (
        <Grid
          item
          xs={12}
          s={8}
          md={6}
          lg={4}
          sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
          key={item.key}
        >
          <Box>
            <img alt={item.title} src={item.img}/>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
