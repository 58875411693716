import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import ShoplinkComponent from "./shoplink-component";
import MessengerComponent from "./messenger-component";

export default function GHContact() {
  return (
    <Grid container>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Box sx={{ m: 3 }}>
          <fieldset>
            <legend>
              <h2>&nbsp;Contact Us&nbsp;</h2>
            </legend>

            <ShoplinkComponent></ShoplinkComponent>
            
            <p>
              If you are looking for a sales point, for wholesale opportunities and for any further
              enquiries please&nbsp;
              <Link href="mailto:georgasheritage@gmail.com">email</Link>              
              &nbsp;us.
            </p>

            <p>
              <b>Address</b>
              <br></br>
              Georgas Heritage
              <br></br>
              Sykea – Monemvasia
              <br></br>
              23052 Greece
              <br></br>
              Email: georgasheritage@gmail.com
            </p>
            <br></br>
          </fieldset>
        </Box>
      </Grid>
    </Grid>
  );
}
